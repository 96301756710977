import { RouteObject, Navigate } from "react-router-dom";
import TransferUang from "presentation/pages/view/transfer-uang/";
import WithDrawUang from "presentation/pages/view/withdraw-uang/";
import CancelUang from "presentation/pages/view/cancel-uang/";
import TransferUangAlfa from "presentation/pages/view/transfer-uang-alfa";
import TransferUangBank from "presentation/pages/view/transfer-uang-bank";

const OperationRoutes: RouteObject[] = [
    {
        path: "/operation",
        children: [
            {
                path: "/operation/transfer-uang",
                element: <TransferUang />,
            },
            {
                path: "/operation/transfer-uang-alfa",
                element: <TransferUangAlfa />,
            },
            {
                path: "/operation/transfer-uang-bank",
                element: <TransferUangBank />,
            },
            {
                path: "/operation/withdraw-uang",
                element: <WithDrawUang />,
            },
            {
                path: "/operation/cancel-transfer-uang",
                element: <CancelUang />,
            },
            {
                index: true,
                element: <Navigate to="/dashboard" />,
            },
        ],
    },
];

export default OperationRoutes;
