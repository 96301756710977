import { get, post } from "config/infra";
import argsToParams from "config/shared/utils/args-to-params"
import { BankRequest, BankResponse, CheckBankRequest, CheckBankResponse } from "domain/entity/Bank"
import BankRepository from "domain/repository/Bank";

export default class BankApi implements BankRepository {

    async getBank(p: BankRequest): Promise<BankResponse> {
        const param = argsToParams(p)
        console.log('param', param)
        return get(`/bank/list?${param}`)
    }

    async postCheckBankAccount(body: CheckBankRequest): Promise<CheckBankResponse> {
        return post('/check-bank-account', body)
    }
}