import TransferUangBankPage from "presentation/pages/view/transfer-uang-bank/TransferUangBankPage";
import useTransferUangBankViewModel from "presentation/pages/viewmodel/transfer-uang-bank";
import DepositApi from "data/Deposit";
import DepositUseCase from "domain/interactor/Deposit";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import GlobalResourceApi from "data/GlobalResource";
import BankUseCase from "domain/interactor/Bank";
import BankApi from "data/Bank";
import MerchantApi from "data/Merchant";
import MerchantUseCase from "domain/interactor/Merchant";

const useCase = new DepositUseCase(new DepositApi());
const bankUseCase = new BankUseCase(new BankApi());
const merchantUseCase = new MerchantUseCase(new MerchantApi());
const globalUseCase = new GlobalResourceUseCase(new GlobalResourceApi());

export default function () {
    const viewModel = useTransferUangBankViewModel({ useCase, bankUseCase, merchantUseCase, globalUseCase });

    return <TransferUangBankPage viewModel={viewModel} />;
}
