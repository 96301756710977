import React from "react";
import LayoutAppBar from "config/components/layout-app-bar";
import { MenuItem, Stack, Typography, Box } from "@mui/material";
import { ListTransaksiDetailViewModel } from "presentation/pages/viewmodel/list-transaksi-detail";
import ButtonRemid from "core/components/remid-button/Button";
import useLanguage from "config/hooks/useLanguage";
import log from "config/log";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "config/components/message";
import { TransactionDetailResponse } from "domain/entity/Auth/model/TransactionDetail";
import { emailObfuscate, phoneObfuscate } from "config/shared/utils/obfuscate";

type ListTransaksiDetailPageProps = {
    viewModel: ListTransaksiDetailViewModel;
};

const ListTransaksiDetailPage: React.FC<ListTransaksiDetailPageProps> = ({
    viewModel,
}) => {
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
        timeZone: "Asia/Jakarta",
    };

    const optCurr: Intl.NumberFormatOptions = {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };

    const formatter = new Intl.DateTimeFormat("id-ID", options);

    const formatterCurr = new Intl.NumberFormat("id-ID", optCurr);

    const { t } = useLanguage();

    const [detail, setDetail] =
        React.useState<TransactionDetailResponse | null>(null);

    const formattedDate = detail?.data?.transactionDateTime
        ? formatter.format(new Date(detail?.data?.transactionDateTime))
        : "";

    const formattedCurr = detail?.data?.amount
        ? formatterCurr.format(Number(detail?.data?.amount))
        : "";

    const maskedPhone = detail?.data?.receiverPhoneNo
        ? phoneObfuscate(detail?.data?.receiverPhoneNo)
        : "";

    const { success, handleSuccessRedirect } = viewModel;

    const location = useLocation();

    const navigate = useNavigate();

    const generateQR = async () => {
        try {
            const result = await viewModel.mutateAsyncQR({
                transactionId: detail?.data?.transDepositId || "",
            });
            const { data } = result;

            navigate("/money-withdrawal", { state: { data } });
        } catch (err) {
            log(err);
        }
    };

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        } else {
            const { data } = location?.state;
            if (!data) {
                log("you shall not pass");
                navigate("/");
            }
            setDetail(data);
        }
    }, [location]);

    return (
        <>
            <LayoutAppBar
                title={t("transaction.transaction_detail")}
                onBack={viewModel.onBack}
            >
                <Stack spacing={3}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "1rem",
                            textAlign: "start",
                            width: "100%",
                        }}
                    >
                        Status
                    </Typography>
                    <Stack
                        spacing={3}
                        sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "0.75rem",
                                textAlign: "start",
                                color: "#00000099",
                            }}
                        >
                            Transaction ID
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                fontWeight: 600,
                                color: "#EBA440",
                                mt: "0px !important",
                            }}
                        >
                            {detail?.data?.transDepositId || ""}
                        </Typography>
                    </Stack>
                    <Stack
                        spacing={3}
                        sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "0.75rem",
                                textAlign: "start",
                                color: "#00000099",
                            }}
                        >
                            {t("transaction.transaction_date")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                mt: "0px !important",
                            }}
                        >
                            {/* 07 Sep 2022 10.50 WIB */}
                            {formattedDate}
                        </Typography>
                    </Stack>
                    <Stack
                        spacing={3}
                        sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "0.75rem",
                                textAlign: "start",
                                color: "#00000099",
                            }}
                        >
                            {t("transaction.money_amount")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                mt: "0px !important",
                            }}
                        >
                            {/* Rp. 1.000.000 */}
                            {formattedCurr}
                        </Typography>
                    </Stack>
                    <hr />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "1rem",
                            textAlign: "start",
                            width: "100%",
                        }}
                    >
                        Detail
                    </Typography>

                    {detail?.data?.productId == "6" ? (
                        <>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.receiverName || ""}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_no")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {maskedPhone}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    Bank Tujuan
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfReceiver || ""}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    No. Rekening
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfReceiver || ""}
                                </Typography>
                            </Stack>
                            <hr />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    textAlign: "start",
                                    width: "100%",
                                }}
                            >
                                {t("transaction.money_deposit_locations")}
                            </Typography>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("confirm_identity.country")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfSender || "-"}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.store_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfSender || "-"}
                                </Typography>
                            </Stack>
                        </>
                    ) : detail?.data?.productId == "7" ? (
                        <>
                            {/* <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_no")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {maskedPhone}
                                </Typography>
                            </Stack> */}
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    Bank Tujuan
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfReceiver || ""}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    No. Rekening
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfReceiver || ""}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.receiverName || ""}
                                </Typography>
                            </Stack>
                            <hr />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    textAlign: "start",
                                    width: "100%",
                                }}
                            >
                                {t("transaction.money_deposit_locations")}
                            </Typography>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("confirm_identity.country")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfSender || "-"}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.store_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfSender || "-"}
                                </Typography>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.receiverName || ""}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.recipient_no")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {maskedPhone}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.mtcn_code")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.mtcn || "-"}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.cancellation_code")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.kodeBatal || "-"}
                                </Typography>
                            </Stack>
                            <hr />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    textAlign: "start",
                                    width: "100%",
                                }}
                            >
                                {t("transaction.money_deposit_locations")}
                            </Typography>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("confirm_identity.country")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfSender || "-"}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.store_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfSender || "-"}
                                </Typography>
                            </Stack>
                            <hr />
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    textAlign: "start",
                                    width: "100%",
                                }}
                            >
                                {t("transaction.money_withdrawal_locations")}
                            </Typography>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("confirm_identity.country")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.localityOfReceiver || "-"}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={3}
                                sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.75rem",
                                        textAlign: "start",
                                        color: "#00000099",
                                    }}
                                >
                                    {t("transaction.store_name")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 600,
                                        mt: "0px !important",
                                    }}
                                >
                                    {detail?.data?.sourceOfReceiver || "-"}
                                </Typography>
                            </Stack>
                        </>
                    )}
                </Stack>

                {detail?.data?.status && ["Menunggu disetor", "Menunggu Scan"].includes(detail.data.status) && (
                    <Stack
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ padding: "30px 0" }}
                    >
                        <ButtonRemid type="submit" onClick={generateQR}>
                            Generate qr code
                        </ButtonRemid>
                    </Stack>
                )}
            </LayoutAppBar>
        </>
    );
};

export default ListTransaksiDetailPage;
