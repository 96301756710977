import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    nameValidation,
    identityNumberValidation,
    phoneValidation,
    transferAmountAlfaValidation,
} from "presentation/validation";
import log from "config/log";
import phoneFormatter from "config/shared/utils/phone-formatter";
import { useQueries, useMutation } from "react-query";
import DepositUseCase from "domain/interactor/Deposit";
import { GlobalContext } from "config/context/GlobalContext";
import { DepositFormRequest } from "domain/entity/Deposit";
import { DetailTransaksi } from "config/components/field-detail-transaksi";
import { removeDot } from "config/shared/utils/remove-dot.index";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import useLanguage from "config/hooks/useLanguage";
import BankUseCase from "domain/interactor/Bank";
import currencyFormatter from "config/shared/utils/currency-formatter";
import MerchantUseCase from "domain/interactor/Merchant";
import { phoneObfuscate } from "config/shared/utils/obfuscate";

const initialValues = {
    receiverName: "",
    // receiverNoIdCard: "",
    receiverPhoneNo: "",
    amount: "",
    sourceOfFund: "",
    sendIntention: "",
};

const initialValues2 = {
    localityOfReceiver: "", // Bank default
    sourceOfReceiver: "", // No.Rek
    localityOfSender: "1", // Indonesia
    sourceOfSender: "Alfamart", // Alfamart
    accountName: "",
};

const validationSchema = yup.object({
    receiverName: nameValidation,
    // receiverNoIdCard: identityNumberValidation,
    receiverPhoneNo: phoneValidation,
    amount: transferAmountAlfaValidation,
    sourceOfFund: yup.string().required("validation.withdraw1"),
    sendIntention: yup
        .string()
        .required("validation.withdraw2"),
});

const validationSchema2 = yup.object({
    localityOfReceiver: yup.string().required("validation.localityOfReceiver_alfa"),
    sourceOfReceiver: yup.string().required("validation.sourceOfReceiver_alfa"),
    localityOfSender: yup.string().required("validation.withdraw3"),
    sourceOfSender: yup.string().required("validation.withdraw4"),
});

type TransferUangUseCases = {
    useCase: DepositUseCase;
    bankUseCase: BankUseCase;
    merchantUseCase: MerchantUseCase;
    globalUseCase: GlobalResourceUseCase;
};

const useTransferUangViewModel = ({
    globalUseCase,
    bankUseCase,
    merchantUseCase,
    useCase,
}: TransferUangUseCases) => {
    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const { t } = useLanguage();

    const navigate = useNavigate();

    const [nextStep, setNextStep] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const { mutateAsync: getCheck, isLoading: pinloading } = useMutation(() =>
        useCase.getCheckUser()
    );

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCheck(); // Await the asynchronous function call
                // Handle the result as needed
            } catch (err) {
                const { response } = err as any; // should only handle axios Error

                if (response && response.data) {
                    const { message, rc } = response.data;

                    if (rc === "0056") {
                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("general.failed1"),
                                type: "warning",
                                btnText: "Kembali",
                                subHeader: t("general.failed2"),
                                onClick: () => {
                                    navigate("/dashboard/home");

                                    dispatch({ type: "CLOSE_OVERLAY" });
                                }
                            },
                        });
                    } else {
                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("general.general_error"),
                                type: "warning",
                                btnText: "Kembali",
                                subHeader: t("rc." + rc),
                                onClick: () => {
                                    navigate("/dashboard/home");

                                    dispatch({ type: "CLOSE_OVERLAY" });
                                }
                            },
                        });
                    }
                }
            }
        };

        fetchData(); // Call the async function
    }, []);

    // form 1 -> detail transfer
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { onChange: onChangePhone, ...phoneRegister } =
        register("receiverPhoneNo");

    const handlePhoneChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.target.value = phoneFormatter(e.target.value);
        onChangePhone(e);
    };

    const { onChange: onChangeName, ...nameRegister } =
        register("receiverName");

    const handleNameChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
        onChangeName(e);
    };

    // form 2 --> lokasi pengirim dan penerima uang
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        getValues: getValues2,
        control: control2,
        setValue,
    } = useForm({
        defaultValues: initialValues2,
        resolver: yupResolver(validationSchema2),
        mode: "onTouched",
    });

    const [{ data: sumberDanaList }, { data: tujuanKirimList }, { data: bankList }, { data: merchantList }] = useQueries([
        {
            queryKey: "/sumberdana",
            queryFn: () => globalUseCase.getDepositSumberDana(),
            staleTime: Infinity,
        },
        {
            queryKey: "/tujuankirim",
            queryFn: () => globalUseCase.getDepositTujuanKirim(),
            staleTime: Infinity,
        },
        {
            queryKey: "/bank",
            queryFn: () => bankUseCase.getBank({
                bankCode: "",
                name: "",
            }),
            staleTime: Infinity,
        },
        {
            queryKey: "/merchant",
            queryFn: () => merchantUseCase.getMerchant({
                id: "",
                name: "",
            }),
            staleTime: Infinity,
        },
    ]);

    const handleBackButtonClick = () => setNextStep(false);

    const redirectToHome = () => {
        navigate("/dashboard/transaction");
    };

    const onSubmit = () => {
        dispatch({
            type: "SHOW_OVERLAY",
            payload: {
                header: t("general.overlay1"),
                subHeader:
                    t("general.overlay2"),
                btnText: t("auth.next_text"),
                onClick: () => {
                    setNextStep(true);
                    dispatch({ type: "CLOSE_OVERLAY" });
                },
                negativeBtnText: t("auth.cancel"),
                onNegativeClick: () => {
                    dispatch({ type: "CLOSE_OVERLAY" });
                },
            },
        });
    };

    const {
        mutateAsync,
        isLoading: loadingInsert,
        isSuccess: successInsert,
    } = useMutation("/deposit/insert", (body: DepositFormRequest) =>
        useCase.postDepositForm(body)
    );

    const [submitResult, setSubmitResult] = React.useState<
        Array<DetailTransaksi>
    >([]);

    const onSubmit2 = async () => {
        try {
            const userId = user?.userId ? user.userId.toString() : "";

            const form1Values = getValues();

            const form2Values = getValues2();

            const { data } = await mutateAsync({
                userId,
                productId: "6",
                ...form1Values,
                ...form2Values,
                receiverPhoneNo: form1Values.receiverPhoneNo.replaceAll(" ", ""),
                amount: removeDot(form1Values.amount),
            });

            const { depositId, amount } = data; // status

            const { receiverName, receiverPhoneNo } = data; // Detail

            const { sourceOfSender, localityOfSender } = data; // Lokasi Setor uang

            const { sourceOfReceiver, localityOfReceiver } = data;

            setSubmitResult([
                {
                    header: "Status",
                    detailItems: [
                        {
                            label: "Order ID",
                            value: depositId,
                            color: "secondary",
                        },
                        {
                            label: t("transaction.money_amount"),
                            // value: amount.toString(),
                            value: currencyFormatter(
                                amount || ""
                            ),
                        },
                    ],
                },
                {
                    header: "Detail",
                    detailItems: [
                        {
                            label: t("transaction.recipient_name"),
                            value: receiverName,
                        },
                        {
                            label: t("profile.phone"),
                            value: phoneObfuscate(receiverPhoneNo),
                        },
                        {
                            label: "Bank Tujuan",
                            value: localityOfReceiver,
                        },
                        {
                            label: "No. Rekening",
                            value: sourceOfReceiver,
                        },
                    ],
                },
                {
                    header: t("transaction.depo_loc"),
                    detailItems: [
                        {
                            label: t("confirm_identity.country"),
                            value: localityOfSender,
                        },
                        {
                            label: t("transaction.store_name"),
                            value: sourceOfSender,
                        },
                    ],
                },
                // {
                //     header: t("transaction.withdrawal_loc"),
                //     detailItems: [
                //         {
                //             label: t("confirm_identity.country"),
                //             value: localityOfReceiver,
                //         },
                //         {
                //             label: t("transaction.store_name"),
                //             value: sourceOfReceiver,
                //         },
                //     ],
                // },
            ]);
        } catch (err) {
            log('catch: ' + err);
            const { response = null } = err as any;

            if (response && response.data) {
                const { message, rc } = response.data;

                dispatch({
                    type: "SHOW_OVERLAY",
                    payload: {
                        header: t("general.general_error"),
                        type: "warning",
                        btnText: "Kembali",
                        subHeader: t("rc." + rc),
                    },
                });
            }
        }
    };

    return {
        control,
        getValues,
        register,
        handleSubmit,
        errors,
        onSubmit,
        nextStep,
        handleBackButtonClick,
        getValues2,
        register2,
        handleSubmit2,
        errors2,
        onSubmit2,
        success,
        redirectToHome,
        handlePhoneChange,
        phoneRegister,
        handleNameChange,
        nameRegister,
        sumberDanaList,
        tujuanKirimList,
        submitResult,
        loadingInsert,
        control2,
        successInsert,
        bankList,
        merchantList,
        setValue,
    };
};

export type TransferUangViewModel = ReturnType<typeof useTransferUangViewModel>;

export default useTransferUangViewModel;
