import React from "react";
import useLanguage from "config/hooks/useLanguage";
import { Box, MenuItem, Stack, TextField, Typography, InputAdornment } from "@mui/material";
import { TransferUangViewModel } from "presentation/pages/viewmodel/transfer-uang-bank";
import ButtonRemid from "core/components/remid-button/Button";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import SliderModal from 'config/components/slider-modal'
import { Controller } from "react-hook-form";
import LoopIcon from '@mui/icons-material/Loop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type FormLokasiPengirimanUangType = {
    viewModel: TransferUangViewModel;
};

const FormLokasiPengirimanUang: React.FC<FormLokasiPengirimanUangType> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const [searchQuery, setSearchQuery] = React.useState("");
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const {
        handleSubmit2,
        onSubmit2,
        register2,
        errors2,
        control2,
        bankList,
        merchantList,
        isCheckButtonClicked,
        isCheckButtonLoading,
    } = viewModel;

    const filteredBankList =
        Array.isArray(bankList?.data) && bankList?.data.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const handleOpenDialog = () => {
        setIsDialogOpen(val => !val);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(val => !val);
        fieldRef.current!.blur()
    };

    const handleCheckButtonClick = async (fieldValue: string) => {
        const { getValues2 } = viewModel;

        const form2Values = getValues2();
        const localityOfReceiver = form2Values.localityOfReceiver || "";  // Get the value of localityOfReceiver from form2

        viewModel.handleCheckButtonClick(fieldValue, localityOfReceiver);
    };

    const fieldRef = React.useRef<HTMLInputElement | null>(null)

    return (
        <form onSubmit={handleSubmit2(onSubmit2)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    {t("transaction.money_deposit_locations")}
                </Typography>
                <Controller
                    name="localityOfSender"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("confirm_identity.country")}
                            error={Boolean(errors2.localityOfSender)}
                            helperText={t(errors2.localityOfSender?.message)}
                        >
                            <MenuItem value="1">Indonesia</MenuItem>
                        </TextFieldRemid>
                    )}
                />
                <Controller
                    name="sourceOfSender"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("transaction.store_name")}
                            error={Boolean(errors2.sourceOfSender)}
                            helperText={t(errors2.sourceOfSender?.message)}
                        >
                            {Array.isArray(merchantList?.data)
                                ? merchantList?.data.map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    );
                                })
                                : []}
                        </TextFieldRemid>
                    )}
                />

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    Tujuan Pengiriman Uang
                </Typography>
                <Controller
                    name="localityOfReceiver"
                    control={control2}
                    render={({ field: { value, ...restField } }) => (
                        <TextFieldRemid
                            {...restField}
                            value={bankList?.data?.find(item => item.id === value)?.name ?? ""}
                            fullWidth
                            shrink
                            contentEditable={false}
                            label="Bank"
                            error={Boolean(errors2.localityOfReceiver)}
                            helperText={t(errors2.localityOfReceiver?.message)}
                            onFocus={handleOpenDialog}
                            ref={fieldRef}
                        >
                        </TextFieldRemid>
                    )}
                />
                <Controller
                    name="sourceOfReceiver"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            number
                            fullWidth
                            shrink
                            inputProps={{
                                maxLength: 16,
                            }}
                            label="Nomor Rekening"
                            error={Boolean(errors2.sourceOfReceiver)}
                            helperText={t(errors2.sourceOfReceiver?.message)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ButtonRemid
                                            variant="outlined"
                                            color="primary"
                                            sx={{ minWidth: '40px !important' }}
                                            onClick={() => {
                                                handleCheckButtonClick(field.value);
                                            }}
                                            disabled={isCheckButtonLoading}
                                            endIcon={isCheckButtonLoading ? <LoopIcon sx={{ fontSize: 20 }} /> : 'Cek'}
                                        >
                                            {/* {isCheckButtonLoading ? 'Checking...' : 'Cek'} */}
                                        </ButtonRemid>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />

                {isCheckButtonClicked && (
                    <>
                        <Controller
                            name="accountName"
                            control={control2}
                            render={({ field }) => (
                                <TextFieldRemid
                                    {...field}
                                    fullWidth
                                    shrink
                                    label="Nama Pemilik Rekening"
                                    value={field.value || ''}
                                    InputProps={{
                                        endAdornment: isCheckButtonClicked ? (
                                            <InputAdornment position="end">
                                                <CheckCircleIcon sx={{ color: 'green' }} />
                                            </InputAdornment>
                                        ) : null
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled
                                />
                            )}
                        />
                    </>
                )}


                <SliderModal disableRestoreFocus onClose={handleCloseDialog} open={isDialogOpen}>
                    <Box
                        sx={{ position: "sticky", top: 0, background: "#fff", zIndex: 69 }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "1rem",
                                textAlign: "start",
                                width: "100%",

                            }}
                        >
                            Pilih Bank Tujuan Kamu
                        </Typography>
                        <TextField
                            fullWidth
                            label="Cari nama bank disini"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Box>

                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "1rem",
                            textAlign: "start",
                            width: "100%",
                        }}
                    >
                        List Bank Tujuan
                    </Typography>

                    <Box sx={{ maxHeight: "calc(60vh - 7rem)", overflow: "auto" }}>
                        {filteredBankList &&
                            filteredBankList.map((item) => (
                                <MenuItem
                                    sx={{ fontSize: "0.875rem" }}
                                    key={item.bankCode}
                                    value={item.id}
                                    onClick={() => {
                                        viewModel.setValue('localityOfReceiver', item.id);

                                        handleCloseDialog();
                                    }}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                    </Box>

                </SliderModal>
                <ButtonRemid disabled={!isCheckButtonClicked || viewModel.loadingInsert} type="submit">
                    {t("general.send")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormLokasiPengirimanUang;
