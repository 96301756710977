import React from "react";
import { GlobalContext } from "config/context/GlobalContext";
import SignUpUseCase from "domain/interactor/Auth/SignUpUseCase";
import FormSignUp from "domain/entity/Auth/model/FormSignUp";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import phoneValidation from "presentation/validation/phone";
import passwordValidation from "presentation/validation/password";
import {
    isPasswordEquals,
    isPasswordFormatValid,
    isPasswordLengthValid,
    passwordHelperData,
    passwordHelperDataValidate,
} from "config/shared/utils/password";
import phoneFormatter, {
    unFormatPhone,
} from "config/shared/utils/phone-formatter";
import OTPUseCase from "domain/interactor/OTP";
import { OTPPayload } from "../auth-otp";
import useLanguage from "config/hooks/useLanguage";
import { useMutation } from "react-query";
import { PraRegisterRequest } from "domain/entity/Auth/model/Register";

const validationSchema = yup.object({
    phoneNumber: phoneValidation,
    email: yup.string().email("Format email salah, contoh: rem@remid.com"),
    password: passwordValidation,
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password tidak sama"),
});

const initialValues: FormSignUp = {
    phoneNumber: "",
    email: "",
    confirmPassword: "",
    password: "",
};

type RegisterViewModelProps = {
    registerUseCase: SignUpUseCase;
    otpUseCase: OTPUseCase;
};

const useAuthSignUpViewModel = ({
    otpUseCase,
    registerUseCase,
}: RegisterViewModelProps) => {
    const { t } = useLanguage();

    const [, dispatch] = React.useContext(GlobalContext);

    // const [showOTP, setShowOTP] = React.useState(successGenerateOTP);

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const [TOSCheck, setTOSCheck] = React.useState(false);

    const [captchaStatus, setCaptchaStatus] = React.useState(false);

    const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        getValues,
    } = useForm<FormSignUp>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { onChange: onChangePhone, ...phoneRegister } =
        register("phoneNumber");

    const handleOnChangePhoneNumber = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.target.value = phoneFormatter(e.target.value);
        onChangePhone(e);
    };

    const [_phoneNumber, _password, _confirmPassword] = watch([
        "phoneNumber",
        "password",
        "confirmPassword",
    ]);

    const showPasswordHelper = _password !== "";

    if (showPasswordHelper) {
        /**
         * harcoded index
         *  {0} - password length
         *  {1} - password format
         *  {2} - same password and confirm
         */

        passwordHelperData[0].status = isPasswordLengthValid(_password);
        passwordHelperData[1].status = isPasswordFormatValid(_password);
        passwordHelperData[2].status = isPasswordEquals(
            _password,
            _confirmPassword
        );
    }

    const validatePassword = passwordHelperDataValidate(passwordHelperData);

    const [formSubmitted, setFormSubmitted] = React.useState(false);

    const submitButtonStatus =
        TOSCheck && captchaStatus && _phoneNumber !== "" && validatePassword;

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const {
        mutateAsync: praRegister,
        isLoading: loadingOTP,
        isSuccess: successGenerateOTP,
    } = useMutation("pra-register", (body: PraRegisterRequest) => registerUseCase.praRegister(body));

    const onSubmit = (otp: string, method: string) => {
        return registerUseCase.execute({
            email: getValues("email"),
            phone: getValues("phoneNumber").replaceAll(" ", ""),
            password: getValues("password"),
            otp,
            method,
        });
    };

    /**
     *   method ini digunakan untuk mendapatkan OTP untuk register
     */
     const onSubmitOTP = async () => {
        try {
            const phone = getValues("phoneNumber").replaceAll(" ", "");
            const email = getValues("email")?.toLowerCase();
            const password = getValues("password")

            const result = await praRegister({
                email: email || "",
                phone,
                password,
                token: captchaToken || "",
            });

            setFormSubmitted(true)
            console.log("Registration successful:", result);
        } catch (error) {
            console.error("Error during registration:", error);
            setFormSubmitted(false)

            const { response } = error as any;

            if (response && response.data) {
                const { message, rc } = response.data;
                if (rc === "0025") {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.register_error"),
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                } else {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.register_error"),
                            subHeader: message,
                            type: "warning",
                        }
                    })
                }

                console.error("Server response data:", response.data);
            }
        }

        // try {
        //     const phone = getValues("phoneNumber").replaceAll(" ", "");
        //     const email = getValues("email");
        //     const type = "register";

        //     const result = await postRegisterOTP({
        //         userId: "",
        //         email: email || "",
        //         phone,
        //         method: "sms",
        //         type,
        //     });

        //     /** otp props */

        //     otpDataRef.current = {
        //         userId: "",
        //         email: getValues("email") || "",
        //         phone: _phoneNumber.replaceAll(" ", ""),
        //         method: "sms",
        //         type: "register",
        //         sendWhere: "phone",
        //     };

        //     /** hacky */
        //     localStorage.setItem(
        //         "otp",
        //         JSON.stringify({
        //             register: {
        //                 blockCount: result?.data?.blockCount || 1,
        //                 blocked: false,
        //                 startTime: new Date().getTime(),
        //             },
        //         } as TimerObj)
        //     );
        // } catch (error) {
        //     const { response } = error as any;

        //     if (response && response.data) {
        //         const { message, rc } = response.data;
        //         if (rc === "0025") {
        //             dispatch({
        //                 type: "SHOW_OVERLAY",
        //                 payload: {
        //                     header: t("general.register_error"),
        //                     subHeader: t("rc." + rc),
        //                     type: "warning",
        //                 },
        //             });
        //         }
        //     }
        // }
    };

    const onCaptchaChange = (token: string | null) => {
        setCaptchaToken(token);
        setCaptchaStatus(true);
    };

    const onVerificationBack = () => {
        // setShowOTP(false);
        setFormSubmitted(false);
    };

    const onVerificationSubmit = () => {
        setSuccess(true);
    };

    const handleSuccessRedirect = () => {
        navigate("/login", {
            state: {
                data: {
                    phoneNumber: unFormatPhone(_phoneNumber),
                    password: _password,
                },
            },
        });
    };

    const handleTOSRedirect = () => {
        localStorage.setItem("register-form", JSON.stringify(getValues()));
        navigate("/tos");
    };

    // React.useEffect(() => {
    //     setShowOTP(successGenerateOTP);
    // }, [successGenerateOTP]);

    React.useEffect(() => {
        const formsValue = JSON.parse(
            localStorage.getItem("register-form") || "null"
        );

        if (!formsValue) return;

        Object.keys(initialValues).forEach((key) => {
            const value = formsValue[key];
            if (value) {
                setValue(key as keyof typeof initialValues, value);
            }
        });

        localStorage.removeItem("register-form");
    }, []);

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        submitButtonStatus,
        showPassword,
        setShowPassword,
        showConfirmPassword,
        setShowConfirmPassword,
        showPasswordHelper,
        passwordHelperData,
        onVerificationBack,
        otpDataRef,
        success,
        onVerificationSubmit,
        handleSuccessRedirect,
        setValue,
        control,
        handleOnChangePhoneNumber,
        phoneRegister,
        onCaptchaChange,
        setTOSCheck,
        handleTOSRedirect,
        // loadingOTP,
        // successGenerateOTP,
        onSubmitOTP,
        setSuccess,
        getValues,
        formSubmitted,
        // showOTP,
        // selectedMethod,
        // handleMethodSelect,
    };
};

export type SignUpViewModelType = ReturnType<typeof useAuthSignUpViewModel>;

export default useAuthSignUpViewModel;
